.tagify__dropdown__wrapper {
  max-height: 120px;
}

.tagify__input {
  padding: 0;
  width: 100%;
  margin: 10px;
}

.tagify {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
}

.tagify__tag {
  flex: 1 1 25%;
  box-sizing: border-box;
}

.tagify.tagify__dropdown__wrapper {
  max-height: 100px;
}

.tagify__dropdown__item {
  font-size: 14px;
}
